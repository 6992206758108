<template>
<v-col md='4' sm='6'>
  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="80%"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      src="@/assets/infra.png"
    ></v-img>

    <v-card-title>{{card.name}}</v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>

        <div class="grey--text ms-4">
          4.5 (413)
        </div>
      </v-row>

      <div class="my-4 text-subtitle-1">
        $ 30 Mill
      </div>

      <div>GovNet Connectivity (Expansion of GovNet to all MDAs and LGAs</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <!-- <v-card-title>Tonight's availability</v-card-title> -->

    <!-- <v-card-text>
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip>5:30PM</v-chip>

        <v-chip>7:30PM</v-chip>

        <v-chip>8:00PM</v-chip>

        <v-chip>9:00PM</v-chip>
      </v-chip-group>
    </v-card-text> -->

    <v-card-actions>
      <v-btn
      link
      to="/components/component_projects"
        color="deep-purple lighten-2"
        text
      >
        View Projects
      </v-btn>
    </v-card-actions>
  </v-card>
</v-col>
</template>

<script>
  export default {
      props:['card'],
    data: () => ({
      loading: false,
      selection: 1,
    }),

    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>