<template>
  <v-container>
    <v-row>
      <SubCard :card="card" v-for="card in subcomponent" :key="card.name" />
    </v-row>
  </v-container>
</template>

<script>
import SubCard from './SubCard.vue'
  export default {
    components:{SubCard},
    data: () => ({
      loading: false,
      selection: 1,
      subcomponent:[{name:'Rural  Broadband for Development',pic:'@/assets/infra.png'},{name:'Connected Government',pic:'@/assets/infra.png'}]
    }),

    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>